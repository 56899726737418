import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersJobPosting = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Lead UX Designer</h1>
      <div className="jobdescription col-md-12">
        Great products are created when nothing falls through the cracks. Push the boundaries of what user experience can be by defining how Uno can help people and organizations change their behavior.
      </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Work with our product leads, industrial designers, visual designer
              director, and developers to define the Uno user experience.
            </li>
            <li>
              Ensure the entire customer journey—acquisition, onboarding,
              repeated use, customer support—is clear, consistent and
              delightful.
            </li>
            <li>
              Collaborate across departments to iterate and help resolve product
              limitations.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              Inherently strategic and understand how to bridge the gap between
              business goals and product execution.
            </li>
            <li>
              A critical thinker who can ask hard questions and come up with
              answers.
            </li>
            <li>Observative to behavior and user’s needs. </li>
            <li>
              Able to help others see what’s truly important versus nice to
              have.{" "}
            </li>
            <li>Sensitive to language, imagery, iconography and sound.</li>
            <li>Logical in your process and can explain it to others.</li>
            <li>A great listener and can read between the lines.</li>
            <li>A clear communicator in-person and on paper.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              5+ years of experience working UX designer at a tech company or
              agency.
            </li>
            <li>
              Portfolio demonstrating hands-on experience designing digital
              products, e-com experiences, mobile apps, etc.{" "}
            </li>
            <li>Extensive experience wireframing for mobile-first design.</li>
            <li>
              Deep know-how in defining and building digital product from
              research through delivery and iteration.{" "}
            </li>
            <li>
              Ability to look at processes and journeys both from a bird’s eye
              view and bottom-up.
            </li>
            <li>Understanding of user testing.</li>
            <li>
              High proficiency in designing and prototyping with Sketch, Adobe
              XD and Invision.
            </li>
            <li>
              Experience designing digital interfaces for physical products is a
              plus.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersJobPosting;
